// layout
import customiseReducer from "./customise/customiseSlice";

// Authentication Module
import Login from "./auth/login/loginSlice";

//notification
import Notification from "./notifications/notificationSlice";

// mini
import Mini from "./mini/miniSlice";
// Reports
import Reports from "./reports/reportsSlice";
// system APIs
import System from "./system/systemSlice";
//Masters
import Departments from "./Masters/Departments/departmentSlice";
import Designation from "./Masters/Designation/designationSlice";
import Roles from "./Masters/Roles/roleSlice";
import Organization from "./Masters/Organizations/organizationSlice";
import Location from "./Masters/Location/locationSlice";
import Leavereasons from "./Masters/Leavereasons/leavereasonsSlice";
import State from "./Masters/State/stateSlice";
import City from "./Masters/City/citySlice";
import LateReason from "./Masters/lateReason/lateReasonSlice";
import OnSiteLocation from "./Masters/OnSiteLocation/onSiteLocationSlice";
import Shifts from "./Masters/Shifts/shiftSlice";
import TaskStatus from "./Masters/TaskStatus/stateTaskStatus";
import TemplateTask from "./Masters/TemplateTask/templateTaskSlice";
import TaskTemplateCreation from "./Masters/TaskTemplateCreation/taskTemplateCreationSlice";
import LeaveDefinition from "./Masters/LeaveDefinition/leaveDefinitionSlice";
import Holiday from "./Masters/Holiday/holidaySlice";

//userProfile
import UserProfile from "./userProfile/profileSlice";

//Setting
import ManageUsers from "./settings/ManageUsers/manageUserSlice";
import ManageProfile from "./settings/ManageProfile/profileSlice";
import LocationManager from "./settings/LocationManager/locationManagerSlice";
import Managers from "./settings/Managers/ManagersSlice";
import Approval from "./settings/Approval/approvalSlice";
import ImportExport from "./settings/ImportExport/importExportSlice";
import BackupDatabase from "./settings/BackupDatabse/backupDataBaseSlice";
import ManageCompany from "./settings/ManageCompany/manageCompanySlice";
import AppSettings from "./settings/AppSettings/appsettingsSlice";

//Side Menu
import Attendance from "./sideMenu/attendance/attendanceSlice";
import Leave from "./sideMenu/leave/leaveSlice";
import Late from "./sideMenu/late/lateSlice";
import OnSite from "./sideMenu/onSite/onSiteSlice";
import Task from "./sideMenu/taskManagement/taskManagementSlice";
import LateRequests from "./sideMenu/lateRequests/lateRequestSlice";
import MonthAttendance from "./sideMenu/monthlyAttendance/monthlyAttendanceSlice"
import AttendCorrection from "./sideMenu/attendanceCorrection/attendCorrectionSlice";
import ShiftAllocation from "./sideMenu/shiftAllocation/shiftAllocationSlice";

// Dashboard
import Dashboard from "./dashboard/dashboardSlice";

export default {
  // Layout,
  customise: customiseReducer,

  // Authentication
  login: Login,

  //Notification
  notification: Notification,

  // mini
  mini: Mini,
  // Reports
  reports: Reports,
  // system APIs
  system: System,
  //Masters
  designation: Designation,
  departments: Departments,
  roles: Roles,
  organization: Organization,
  location: Location,
  lateReason: LateReason,
  states: State,
  city: City,
  leavereasons: Leavereasons,
  onSiteLocation: OnSiteLocation,
  shifts: Shifts,
  taskStatus: TaskStatus,
  templateTask: TemplateTask,
  taskTemplateCreation: TaskTemplateCreation,
  leaveDefinition: LeaveDefinition,
  holiday: Holiday,

  //userProfile
  userProfile: UserProfile,
  //userProfile

  //Settings
  manageUsers: ManageUsers,
  manageProfile: ManageProfile,
  locationManager: LocationManager,
  managers: Managers,
  approval: Approval,
  importExport: ImportExport,
  backupDatabase: BackupDatabase,
  manageCompany: ManageCompany,
  appSettings: AppSettings,

  //SideMenu
  attendance: Attendance,
  late: Late,
  leave: Leave,
  onSite: OnSite,
  task: Task,
  lateRequests: LateRequests,
  monthAttendance: MonthAttendance,
  attendCorrection: AttendCorrection,
  shiftAllocation: ShiftAllocation,

  // Dashboard
  dashboard: Dashboard,
};
