import { createSlice } from "@reduxjs/toolkit";

export const roleSlice = createSlice({
  name: "roles",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    rolesList: [],
    model: false,
    error_msg: null,
    rowdata: {},
    rolesParams: {
      no_of_pages: 0,
      listCount: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getRoles: (state) => {
      if (state.rolesParams?.page == 1) {
        return {
          ...state,
          loading: true,
          rolesList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    rolesListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.rolesParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.rolesList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.rolesParams?.page_size
      );
      return {
        ...state,
        rolesList: list,
        listCount: response.count,
        loading: false,
        rolesParams: {
          ...state.rolesParams,
          no_of_pages: noofpages,
        },
      };
    },
    SetRolesParams: (state, action) => {
      return {
        ...state,
        rolesParams: action.payload,
      };
    },
    roleAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    roleAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    roleDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    roleById: (state, action) => {
      return {
        ...state,
        loading: false,
      };
    },
    roleByIdSuccessful: (state, action) => {
      console.log("roleByIdSuccessful", action.payload);
      return {
        ...state,
        loading: false,
        model: true,
        rowdata: {
          ...action.payload,
        }
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getRoles,
  rolesListSuccessful,
  SetRolesParams,
  roleAdd,
  roleAddSuccessful,
  roleById,
  roleByIdSuccessful,
  roleDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
} = roleSlice.actions;

export default roleSlice.reducer;
